import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { useConfiguration } from "~/contexts/configuration";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import evaUtils from "~/utils/eva-utils";
import productUtils from "~/utils/product-utils";

import ProductCarouselSwiper from "./product-carousel-swiper";

type Props = {
  productReferenceFields: Editorials.ProductsReferenceById | Editorials.ProductsReferenceBySet;
  enableLoop: boolean;
  listScope?: string;
};

export default function ProductCarouselStatic({ productReferenceFields, enableLoop, listScope }: Props) {
  const { eva } = useConfiguration();
  const { data } = useQuery({
    queryKey: ["productCarousel", listScope],
    staleTime: Infinity,
    queryFn: async () => {
      const sortBy = productReferenceFields.sortBy;
      const sort = productUtils.getSortFieldDescriptorByContentful(sortBy);
      const maxProductsInCarousel = Constants.MAX_PRODUCTS_IN_CAROUSEL;
      if ("productIds" in productReferenceFields) {
        const productIds = productReferenceFields.productIds;
        const response = await evaUtils.getProductsByIdsWithChildren(eva, productIds, sort, 1, maxProductsInCarousel);
        return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
      } else {
        const productSetId = (productReferenceFields as Editorials.ProductsReferenceBySet).productSetId;
        const response = await evaUtils.getProductsBySetWithChildren(eva, productSetId, sort, 1, maxProductsInCarousel);
        return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
      }
    },
    enabled: !!eva,
  });
  return <ProductCarouselSwiper products={data} listScope={listScope} enableLoop={enableLoop} />;
}
